.product{
    display: flex;
    align-items: center;
    padding-top: 20px;
    gap: 16px;
    position: relative;
    padding-bottom: 20px;
    &.unavailable{
        opacity: 0.7;
        &::before{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            height: 1px;
            background: #000;
            width: 100%;
        }
    }
    &_image img{
        width: 70px;
        height: 70px;
        border-radius: 10%;
        object-fit: cover;
    }
    &_title{
        max-width: 100%;
        height: 100%;
        a{
            padding: 15px 0;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            line-height: 25px;
        }
    }
    &__control {
        button{
            img {
                width: 20px;
                height: 20px;
                color:black;
                background-color: #fff;
            }
        }
    }
    &_price{
        text-align: right;
        min-width: 60px;
    }
    &_count{
        margin-left: auto;
    }

    @media screen and (max-width: 768px){
        align-items: flex-start;
        justify-content: space-evenly;
        padding-top: 20px;
        &_title{
            max-width: 100%;
            width: 100%;
            a{
                display: block;
                width: 100%;
                padding: 0;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 20px;
                line-height: 25px;
                word-break: break-word;
            }
        }

        &_count{
            position: absolute;
            bottom: 14px;
            left: 72px;
            margin-left: 0;
        }

        &_price{
            position: absolute;
            bottom: 20px;
            right: -5px;
        }
        &__control {
            margin-top: 4px;
        }
    }
}
