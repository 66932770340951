@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
}
* {
  padding: 0;
  margin: 0;
  border: 0;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: grayscale;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button, .button {
  cursor: pointer;
  font-family: inherit;
  font-weight: inherit;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6, a, p, span {
  font-size: inherit;
  font-weight: inherit;
  color: inherit
}

body {
  min-height: 100vh;
  width: 100vw;
  font-family: 'Montserrat',Arial,sans-serif;
  font-weight: 400;
  font-size: 16px;
  overflow-y: overlay;
  overflow-x: hidden;
  color: #231f20;
}

html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.App {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.App .main {
  flex: 1 0 auto;
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.3);
    background-clip: content-box;
    border: 3px solid rgba(0, 0, 0, 0.0);
    border-radius: 100px;
  }
  ::-webkit-scrollbar-track-piece {
    background: rgba(0, 0, 0, 0.0);
  }
  ::-webkit-scrollbar-button {
    background: rgba(0, 0, 0, 0.0);
  }
  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.0);
  }
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  @media screen and (max-width: 1248px) {
    max-width: 960px;
  }
  @media screen and (max-width: 1008px) {
    max-width: 100%;
    padding: 0 24px;
  }
}

.section-shop{
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 0;
  background: url("/assets/images/Fon.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(35,31,32,0.7);
    z-index: 1;
  }
  .container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    color: #fff;
    z-index: 2;
    gap: 30px;
    h1{
      font-size: 70px;
      font-weight: 700;
      animation: 0.6s header-animation forwards 0.3s;
      @media screen and (max-width: 1200px) {
        font-size: 60px;
      }
      @media screen and (max-width: 768px){
        font-size: 36px;
      }
      i{
        color: #E03C31;
        font-style: normal;
      }
      b{
        color: #FFC845;
        font-weight: inherit;
      }
      @keyframes header-animation {
        0%{
          transform: translateY(-100%);
        }
        100%{
          transform: translateY(0%);
        }
      }
    }
    p{
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 16px;
      line-height: 30px;
      @media screen and (max-width: 768px){
        font-size: 21px;
      }
    }
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 210px;
      font-size: 18px;
      border-radius: 30px;
      background: #ff7f41;
      color: #231f20;
      font-weight: 700;
      transition: background 0.3s;
      @media screen and (min-width: 568px) {
        &:hover{
          background: #fff;
        }
      }
      @media screen and (max-width: 768px){
        margin: 0 auto;
      }
    }
  }
}
.section-product{
  padding: 60px 0;
  background-color: white;
  .container{
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 50px;
    h1{
      display: flex;
      align-items: center;
      gap:180px;
      font-size: 42px;
      font-weight: 500;
      color: #e03c31;
      span{
        font-size: 12px;
        color: #231f20;
      }
      @media screen and (max-width: 768px) {
        gap: 30px;
      }
    }
    .filter{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      font-weight: 500;
      color: #231f20;
      font-size: 14px;
      span{
        cursor: pointer;
      }
      .active{
        color:#ff7f41;
      }
    }
    .button{
      font-weight: 600;
      width: 150px;
      position: relative;
      height: 50px;
      border-radius: 50px;
      left: 500px;
      background: #ff7f41;
    }
    @media screen and (max-width: 768px){
     .button{
      left: 100px;
      }
    }
    .list{
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      @media screen and (max-width: 808px) {
        justify-content: center;
      }
      .card{
        position: relative;
        border-radius: 30px;
        background: #9ac5ce;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        max-width: 360px;
        width: 100%;
        opacity: 0;
        animation: card-animation 0.3s forwards 0.3s;
        @keyframes card-animation {
          0%{
            opacity: 0;
          }

          100%{
            opacity: 1;
          }
        }
        img{
          width: 100%;
          height: 252px;
          object-fit: cover;
        }
        .card-body{
          padding: 30px;
          h2{
            font-size: 20px;
            font-weight: 700;
            line-height: 1.35;
          }
          h3{
            margin-top: 8px;
            font-size: 14px;
          }
          p{
            margin-top: 8px;
            font-size: 17px;
            font-weight: 400;
            line-height: 1.35;

            &:last-child {
              font-size: 14px;
              line-break: auto;
            }
          }
        }
        .card-footer{
          padding: 0 30px 30px 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;
          margin-top: auto;
          flex-wrap: wrap;

          .cart-item-price {
            width: 100%;
            font-weight: 500;
            font-size: 18px;
            padding: 0 0 13px 6px;
          }
          .counter{
            display: flex;
            gap: 12px;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            border: 1px solid #777;
            border-radius: 30px;
            height: 45px;
            font-weight: 400;
            min-width: 102px;
            font-size: 12px;
            span{
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              font-size: 28px;
              border-radius: 50px;
              &:first-of-type{
                background: linear-gradient(#777,#777),#fff;
                background-position: center;
                background-size: 50% 1px,1px 50%;
                background-repeat: no-repeat;
              }
              &:last-of-type{
                background: linear-gradient(#777,#777),linear-gradient(#777,#777),#fff;
                background-position: center;
                background-size: 50% 1px,1px 50%;
                background-repeat: no-repeat;
              }
            }
          }
          button{
            background: #ff7f41;
            border-radius: 30px;
            padding: 0 30px;
            height: 45px;
            font-weight: 700;
            transition: background 0.3s;
            color: #231f20;

            &.inCart {
              background-color: #25780a;
              color: #ffffff;
            }
            @media screen and (min-width: 568px) {
              &:hover{
                background: #fff;
                color: black;
              }
            }
          }
        }
      }
    }
  }
}
.cart{
  position: fixed;
  z-index: 999;
  bottom: 90px;
  right: 60px;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9ac5ce;
  .counter{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 24px;
    width: 24px;
    border-radius: 24px;
    font-size: 16px;
    background: #E03C31;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg{
    stroke:#ffffff;
    height: 40px;
    width: 40px;

  }

  @media screen and (max-width: 768px) {
    bottom: 24px;
    right: 24px;
    height: 48px;
    width: 48px;
    svg{
      height: 28px;
      width: 28px;
    }
    .counter{
      font-size: 14px;
      height: 20px;
      width: 20px;
    }
  }
}
.filter{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  font-weight: 500;
  color: #231f20;
  font-size: 14px;
  margin-top: 4px;
  span{
    cursor: pointer;
  }
  .active{
    opacity: 0.7;
  }
}
.custom_select{
  &__control{
    height: 60px !important;
    border: none !important;
    border-radius: 10px !important;
    box-shadow: none !important;
  }
  &__value-container{
    padding: 0 !important;
    height: 60px !important;
  }
  &__placeholder{
    font-size: 14px !important;
    padding: 0 20px !important;
  }
  &__input-container{
    margin: 0 !important;
    // padding: 0 !important;
  }
  &__input{
    margin-left: 20px !important;
  }
  &__menu{
    @media screen and (min-width: 768px) {
      position: absolute !important;
    }
    @media screen and (max-width: 768px){
      position: static !important;
    }
  }
  &__indicators{
    height: 60px !important;
  }
  &__single-value-container--has-value{
    height: 60px !important;
  }
  &__single-value{
    white-space: normal !important;
    margin-left: 20px !important;
  }
  &__option{
    height: 50px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 30px 30px !important;
    color:#231f20 !important;
  }
  &__option--is-selected{
    background-color: #9ac5ce !important;
  }
  &__option--is-focused{
    background-color: #9ac5ce !important;
  }
}
