nav, footer, header, aside {
  display: block;
}
.App .footer {
  flex: 0 0 auto;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: sticky;
  @media screen and (max-width: 1248px) {
      max-width: 960px;
  }
  @media screen and (max-width: 1008px) {
      max-width: 100%;
      padding: 0 24px;
  }
}
.footer{
  height: 100%;
  padding: 30px 0;
  background: #FFC845;
  .container{
    &:not(.container_grid){
      margin-top: 40px;
      a{
        font-size: 14px;
        font-weight: 500;
      }
      @media screen and (max-width: 768px) {
        margin-top: 16px;
        a{
          font-size: 12px;
        }
      }
    }
    &.container_grid{
      display: grid;
      gap: 24px;
      grid-template-columns: 1fr 270px 256px;
      grid-template-areas: "header proposal about" "logo address info";
      h1{
        padding: 50px 0 0 0;
        grid-area: header;
        color: #231f20;
        font-size: 90px;
        font-weight: 700;
        span{
          color: #7BB394;
        }
      }
      h2{
        color: #231f20;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 17px;
      }
      a{
        font-size: 18px;
        font-weight: 400;
      }
      .wrapper{
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-weight: 500;
        padding-top: 72px;
        p{
          font-weight: 400;
          font-size: 14px;
        }
        .email{
          color:#ff8562;
        }
      }
      .wrapper:nth-of-type(1){
        grid-area: proposal;
      }
      .wrapper:nth-of-type(2){
        grid-area: about;
      }
      .wrapper:nth-of-type(3){
        grid-area: logo;
        div{
          display: flex;
          gap: 40px;
          align-items: center;
        }
        img{
          height: 53px;
        }
        a{
          img{
            height: 36px;
            width: 35px;
            margin-left: 60px;
          }
        }
      }
      .wrapper:nth-of-type(4){
        grid-area: address;
        line-height: 30px;
      }
      .wrapper:nth-of-type(5){
        grid-area: info;
      }
      @media screen and (max-width: 1100px){
        grid-template-areas: "header proposal" "about logo" "address info";
        h1{
          font-size: 40px;
        }
        h2{
          font-size: 20px;
        }
      }
      @media screen and (max-width: 768px){
        grid-template-areas: "header" "address" "info" "proposal" "about" "logo";
        gap: 16px;
        grid-template-columns: 1fr;
        a{
          font-size: 16px;
        }
        h1{
          font-size: 45px;
        }
        h2{
          font-size: 24px;
          margin-top: 40px;
        }
        .wrapper{
          padding: 0;
          p{
            font-size: 12px;
          }
          .email{
            color:#ff8562;
          }
          img:first-of-type{
            margin-top: 36px;
            margin-bottom: 16px;
          }
          &:nth-of-type(3) a{
            position: absolute;
            top: 126px;
            right: 100px;
            img{
              width: 36px;
              height: 36px;
            }
          }
        }
      }
    }
  }
}