.NewBadge {
  position: absolute;
  top: 14px;
  left: 14px;
  padding: 7px 9px;
  box-shadow: 0px 0px 24px 1px rgba(34, 60, 80, 0.19);
  background: #e03c31;
  border-radius: 30px;
  color: #FFF;
  font-weight: 400;
  -webkit-box-shadow: 0px 0px 24px 1px rgba(34, 60, 80, 0.19);
  -moz-box-shadow: 0px 0px 24px 1px rgba(34, 60, 80, 0.19);
}
