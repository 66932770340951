.MenuItem {
}
.composition{
    button{
        display: block;
        background: #ff7f41;
        border-radius: 30px;
        padding: 6px 8px;
        font-weight: 700;
        margin-top: 12px;
        transition: background 0.3s;
        @media screen and (min-width: 768px) {
            &:hover{
                background: #fff;
            }
        }
    }
}