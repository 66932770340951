.App .header {
    flex: 0 0 auto;
  }
nav, footer, header, aside {
    display: block;
}
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: sticky;
    @media screen and (max-width: 1248px) {
        max-width: 960px;
    }
    @media screen and (max-width: 1008px) {
        max-width: 100%;
        padding: 0 24px;
    }
}
.header{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(35,31,32,0.7);
  transition: background 0.2s;
  .container{
    display: flex;
    align-items: center;
    height: 120px;
  }
  .list{
    position: relative;
    .hidden{
      transition: opacity 0.2s;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 36px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      list-style: none;
      gap: 16px;
      padding: 18px 20px;
      background: #fff;
      width: 280px;
      border-radius: 3px;
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
      &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        height: 12px;
        width: 12px;
        background: #fff;
        transform: translate(-50%,-50%) rotate(-45deg);
      }
      li{
        a{
          font-size: 16px;
          white-space: none;
          color:#e03c31;
        }
      }
    }
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &::after{
      content: '';
      border-style: solid;
      border-width: 4px 3px 0 3px;
      border-color: transparent transparent transparent transparent;
      border-top-color: inherit;
      position: absolute;
      right: -12px;
      top: 50%;
      transform: translateY(-45%);
      transition: transform 0.2s;
    }
    &:hover {
      &::after{
        transform: translateY(-45%) rotate(-180deg);
      }
      &::before{
        height: 200px;
        width: 280px;
      }
      .hidden{
        opacity: 1;
        pointer-events: all;
      }
    }
  }


  #burger{
    display: none;
  }
  label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    padding: 4px;
    gap: 4px;
    cursor: pointer;
    position: sticky;
    span{
      display: block;
      width: 24px;
      height: 4px;
      background: #ff7f41;
      transition: 0.2s;
    }
  }
  @media screen and (max-width: 1000px){
    background: #FFC845;
    nav{
      z-index: -1;
      position: absolute;
      left: 0;
      top: 96px;
      width: 100vw;
      flex-direction: column;
      background: #FFC845;
      padding: 48px 0 48px 0;
      transition: opacity 0.5s;
      a{
        color: #ffffff;
      }
    }
    #burger:not(:checked) ~ nav{
      opacity: 0;
      pointer-events: none;
    }
    #burger:checked ~ nav{
      opacity: 1;
    }
    #burger:checked ~ label{
      span:nth-child(1){
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
      span:nth-child(2){
        width: 0;
      }
      span:nth-child(3){
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
    @keyframes nav-slide-animation {
      0%{
        transform: translateY(-100%);
      }
      100%{
        transform: translateY(0%);
      }
    }
  }
  img{
    max-width: 100px;
    width: 100px;
    height: auto;
    object-fit: contain;
    margin-right: auto;
    @media screen and (max-width: 1000px){
      height: 120px;
    }
  }
  nav{
    display: flex;
    gap: 40px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #231f20;
    a{
      user-select: none;
    }
    @media screen and (min-width: 1000px){
      color: #fff;
    }
    button{
      padding: 10px 30px;
      border-radius: 40px;
      height: 40px;
      width: 240px;
      margin-left: 10px;
      font-weight: 600;
      background: #ff7f41;
      user-select: none;
      transition: background 0.3s;
      @media screen and (min-width: 568px) {
        &:hover{
          background: #fff;
        }
      }
    }
  }
  &.active{
    background: #FFC845;
    position: fixed;
    @media screen and (min-width: 768px) {
      animation: headeractive 0.2s forwards ;
      img{
        max-width: 80px;
      }
      .container{
        height: 110px;
      }
      nav{
        color: #231f20;
      }
    }
  }
  @keyframes headeractive {
    0%{
      transform: translateY(-100%);
    }
    100%{
      transform: translateY(0);
    }
  }
  @media screen and (min-width: 1000px) {
    background: none;
    label{
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    position: fixed;
  }
}