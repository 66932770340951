.count{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    button{
        margin: 5px;
    }
    img {
        width: 16px;
        height: 16px;
        color:black;
        background-color: #fff;
    }
 }