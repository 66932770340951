@mixin invalid {
    &.invalid{
      border: 1px solid red !important;
      color: red !important;
  
      &+label{
        display: block;
        margin: -18px 0 -18px 10px;
        color: red;
        width: auto;
        font-size: 12px;
      }
    }
  }
  
  .cart_modal{
      position: fixed;
      display: flex;
      z-index: 1000;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      padding: 60px 0;
      top: 0;
      left: 0;
      background: rgba(35, 31, 32, 0.80);
      transition: opacity 0.3s;
      overflow-y: overlay;
      &.active{
        opacity: 0;
        pointer-events: none;
      }
      .btn_modal{
        position: fixed;
        top: 10px;
        right: 20px;
        background: rgba(35, 31, 32, 0);
        z-index: 100;
        img{
          width: 35px;
          height: 35px;
        }
      }
      @media screen and (max-width: 768px) {
        .btn_modal{
          position: fixed;
          top: 10px;
          right: 20px;
          background: rgba(35, 31, 32, 0.2);
        }
      }
      .wrapper{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 560px;
        padding: 40px;
        margin: 0 auto;
        background: #ffc845;
        gap: 24px;

        h1{
            color: #e03c31;
            font-size: 30px;
            font-weight: 700;
            text-align: center;
        }

        h2{
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            line-height: 1.55;
            max-width: 400px;
            margin: 0 auto;
        }

        .orderSent {
          position: absolute;
          top: 0;
          left:0;
          display: flex;
          z-index:10;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          font-size:20px;
          font-weight: 500;
        }

        form{
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          gap: 24px;
          button{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            background: #ff7f41;
            border: 1px solid #ff7f41;
            border-radius: 30px;
            color: #231f20;
            font-size: 16px;
            font-weight: 700;
            transition: background-color 0.2s ease-in-out, color 0.2s;
  
            &:disabled {
            height: 60px;
            padding: 0 20px;
              background-color: #ffffff !important;
              background-color: #ffffff !important;
            border: 1px solid #231f20;
              border-color: #231f20 !important;
              border-color: #231f20 !important;
            // border-radius: 10px;
              opacity: 0.5
            }
          }
          label{
            display: none;
          }

          input{
            height: 60px;
            padding: 0 20px;
            border-radius: 10px;
  
            @include invalid;
          }
          label[for="checkbox"]{
            display: block;
            width: auto;
            height: auto;
            font-size: 13px;
            font-weight: 400;
            color: rgb(35, 31, 32);
            a{
              color: rgb(224, 60, 49);
            }
            &::before{
              content: '';
              height: 20px;
              width: 20px;
              border: 2px solid #231f20;
              display: block;
              position: absolute;
              left: -32px;
              top: 0;
              opacity: 0.6;
              transition: 0.2s;
            }
            &::after{
              content: '';
              width: 6px;
              height: 10px;
              border: solid #000;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              display: block;
              position: absolute;
              left: -25px;
              top: 3px;
              opacity: 0.0;
              transition: 0.2s;
            }
          }
          div{
            display: flex;
            gap: 12px;
            input{
              height: 20px;
              width: 20px;
              opacity: 0;
              &:checked + label{
                &::after{
                  opacity: 1;
                }
                &::before{
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  